<template>
  <!-- PrimeBlocks - Dark Sidebar with Grouped Menu Theme - https://primeblocks.org/application/sidebar-layout -->
  <div class="relative flex h-full max-h-screen min-h-screen bg-white text-sm lg:static dark:bg-secondary-900">
    <div class="relative flex">
      <div
        v-if="!navigationStore.showSideNav"
        class="group flex h-full w-4 cursor-e-resize items-center justify-center hover:bg-secondary-200 dark:hover:bg-secondary-900"
        @click="navigationStore.showSideNav = !navigationStore.showSideNav"
      />

      <div
        v-else
        id="app-sidebar-3"
        class="absolute left-0 top-0 z-10 hidden h-screen w-[220px] shrink-0 select-none border-r border-secondary-700 bg-secondary-900 lg:static lg:block"
        data-test="base-menu-nav"
      >
        <div class="flex h-full flex-col">
          <!-- Logo / Org Switcher -->
          <div class="mt-4 flex h-[60px] shrink-0 items-center px-6">
            <img :src="GhostLogo">
          </div>

          <div class="my-2 flex justify-center px-4">
            <OrganizationSwitcher />
          </div>

          <!-- Navigation Menu -->
          <div class="scrollbar overflow-y-auto">
            <NavigationMenu />
          </div>

          <div class="mt-auto">
            <hr class="mx-4 mb-4 border-0 border-t border-secondary-700">

            <div class="m-4 cursor-pointer text-secondary-300 transition-colors duration-150 rounded-border">
              <UserMenu />
            </div>
          </div>
        </div>
      </div>

      <button
        v-if="navigationStore.showSideNav"
        class="absolute -right-2 z-10 h-full w-4 cursor-w-resize"
        @click="navigationStore.showSideNav = !navigationStore.showSideNav"
      />
    </div>

    <div class="relative flex min-h-screen flex-auto flex-col overflow-x-hidden">
      <div class="hidden-print relative flex h-[50px] items-center justify-between border-b bg-white px-8 py-4 border-surface lg:hidden dark:bg-secondary-900">
        <!-- Responsive Menu - Hidden in lg and above -->
        <div class="flex">
          <a
            v-styleclass="{
              selector: '#app-sidebar-3',
              enterFromClass: 'hidden',
              enterActiveClass: 'animate-fadeinleft',
              leaveToClass: 'hidden',
              leaveActiveClass: 'animate-fadeoutleft',
              hideOnOutsideClick: true,
            }"
            class="mr-4 mt-1 block cursor-pointer text-secondary-700 lg:hidden dark:text-secondary-100"
          >
            <Icon name="mdi:menu" class="mt-1.5" size="1.5rem" />
          </a>
        </div>
      </div>

      <!-- Main Content Slot -->
      <div class="scrollbar flex flex-auto flex-col overflow-y-scroll bg-white py-4 pl-4 pr-1 dark:bg-secondary-900 " :class="{ 'pl-1': !navigationStore.showSideNav }">
        <slot />
      </div>
    </div>
  </div>

  <BaseLineChartGradientDefs />
</template>

<script setup lang="ts">
import GhostLogo from '@/public/images/ghost-logo.svg'
import useNavigationStore from '@/stores/navigation'
import OrganizationSwitcher from '~/components/OrganizationSwitcher.vue'

const navigationStore = useNavigationStore()
</script>
