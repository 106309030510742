<template>
  <ul class="m-0 list-none p-2">
    <template v-for="navItem in navItems" :key="navItem.label">
      <NavigationRecursiveSection :nav-item="navItem" :selected-nav-item="selectedNavItem" />
    </template>
  </ul>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia'
import useUsersStore from '@/stores/users'
import { getSettingsMenuOptions } from '@/utils/menu'
import type { MenuOption } from '@/types'

const usersStore = useUsersStore()
const route = useRoute()
const { isFeatureEnabled } = storeToRefs(usersStore)
const reportsEnabled = computed(() => isFeatureEnabled.value('reports'))
const triageEnabled = computed(() => isFeatureEnabled.value('triage'))

const { currentUserIsGhostAdmin } = storeToRefs(usersStore)

const navItems = computed((): MenuOption[] => ([
  {
    label: $t('apps'),
    href: '/apps',
    iconName: 'mdi:apps',
  },
  {
    label: $t('api', 2),
    href: '/apis',
    iconName: 'mdi:wrench-cog',
  },
  {
    label: $t('endpoint', 2),
    href: '/endpoints',
    iconName: 'mdi:star-four-points-outline',
  },
  {
    label: $t('hosts'),
    href: '/hosts',
    iconName: 'mdi:server',
  },
  {
    label: $t('codeRepo', 2),
    href: '/code-repos',
    iconName: 'mdi:file-code-outline',
  },
  // Resources
  {
    id: 'resources',
    label: $t('resource', 2),
    iconName: 'mdi:cloud-outline',
    subItems: [
      {
        label: $t('aws'),
        href: '/resources/aws',
        iconName: 'mdi:aws',
        enabled: true,
        parentGroups: ['resources'],
      },
      {
        label: $t('azure'),
        href: '/resources/azure',
        iconName: 'mdi:microsoft-azure',
        enabled: true,
        parentGroups: ['resources'],
      },
    ],
  },
  // Issues
  {
    id: 'issues',
    label: $t('issue', 2),
    iconName: 'mdi:ghost-outline',
    subItems: [
      {
        label: $t('category', 2),
        href: '/issues/categories',
        iconName: 'mdi:bulletin-board',
        enabled: true,
        parentGroups: ['issues'],
      },
      {
        label: $t('sastFinding', 2),
        href: '/issues/sast-findings',
        iconName: 'mdi:code-block-tags',
        enabled: true,
        parentGroups: ['issues'],
      },
      {
        label: $t('vulnerability', 2),
        href: '/issues/vulnerabilities',
        iconName: 'mdi:alert-box-outline',
        parentGroups: ['issues'],
      },
      {
        label: $t('campaign', 2),
        href: '/issues/campaigns',
        iconName: 'mdi:bullseye-arrow',
        parentGroups: ['issues'],
      },
      {
        label: $t('definition', 2),
        href: '/issues/definitions',
        iconName: 'mdi:view-list-outline',
        parentGroups: ['issues'],
      },
    ],
  },
  // Reports
  {
    id: 'reports',
    label: $t('report', 2),
    iconName: 'mdi:file-document-multiple-outline',
    enabled: reportsEnabled.value || false,
    subItems: [
      {
        label: 'ISO',
        href: '/reports?r=iso',
        iconName: 'mdi:longitude',
        parentGroups: ['reports'],
      },
      {
        label: 'NIST',
        href: '/reports?r=nist',
        iconName: 'mdi:magnify',
        parentGroups: ['reports'],
      },
      {
        label: 'OWASP',
        href: '/reports?r=owasp',
        iconName: 'mdi:bug-outline',
        parentGroups: ['reports'],
      },
      {
        label: 'PCI',
        href: '/reports?r=pci',
        iconName: 'mdi:credit-card-check-outline',
        parentGroups: ['reports'],
      },
      {
        label: 'SOC2',
        href: '/reports?r=soc2',
        iconName: 'mdi:shield-outline',
        parentGroups: ['reports'],
      },
    ],
  },
  // Settings
  {
    id: 'settings',
    label: $t('settings'),
    iconName: 'mdi:cog-outline',
    subItems: [
      {
        label: $t('triage'),
        iconName: 'mdi:shield-bug-outline',
        href: 'https://proto.ghostsecurity.ai/',
        newTab: true,
        enabled: triageEnabled.value || false,
      },
      ...getSettingsMenuOptions(),
      {
        id: 'admin',
        label: $t('admin'),
        iconName: 'mdi:cog-outline',
        subItems: [
          ...getAdminMenuOptions(
            currentUserIsGhostAdmin.value,
          ),
        ],
      },
    ],
  },
]))

const selectedNavItemHref = ref<string>('/apps')
const selectedNavItem = computed(() => findSelectedNavItem(navItems.value, selectedNavItemHref.value))

// Find the selected nav item based on the current route recursively since we have nested nav groups
const findSelectedNavItem = (navItems: MenuOption[], href: string): MenuOption | undefined => {
  for (const item of navItems) {
    if (item.href === href) {
      return item
    }

    if (item.subItems) {
      const foundItem = findSelectedNavItem(item.subItems, href)

      if (foundItem) {
        return foundItem
      }
    }
  }
}

// Remove hidden class from all parent groups of the selected nav item so they expand and show the selected nav item
const handleNavParentState = () => {
  if (selectedNavItem?.value?.parentGroups) {
    for (const parentGroup of selectedNavItem.value.parentGroups) {
      document.querySelector(`#nav-group-accordion-${parentGroup} + ul`)?.classList.remove('hidden')
    }
  }
}

// Watch the route and update the selected nav item accordingly
watch([() => route.path, () => route.query], async () => {
  selectedNavItemHref.value = route.path

  // Handle special cases where the selected nav item is not the same as the route path (e.g. reports with query params)
  if (route.path.startsWith('/reports')) {
    selectedNavItemHref.value = `/reports?r=${route.query.r}`
  }
}, { immediate: true })

watch(selectedNavItem, () => {
  handleNavParentState()
})

onMounted(() => {
  handleNavParentState()
})
</script>
