<template>
  <Select
    v-model="selectedOrg"
    class="select w-full"
    size="small"
    option-label="label"
    :options="orgOptions"
    :disabled="orgOptions.length <= 1"
    append-to=".org-switcher-overlay"
    @change="handleOrgSelected($event.value)"
  />
  <div class="org-switcher-overlay" />
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia'
import useUsersStore from '@/stores/users'

const router = useRouter()
const usersStore = useUsersStore()

const { currentUser } = storeToRefs(usersStore)

const orgOptions = computed(() => {
  const unsortedOptions = currentUser.value?.organizations?.map(org => ({ value: org.id, label: org.name })) || []

  // Sort the organizations alphabetically and return the sorted list
  return unsortedOptions.sort((a, b) => a.label.localeCompare(b.label))
})

const selectedOrg = ref(orgOptions.value.find(org => org.value === currentUser.value?.organization_id))

const handleOrgSelected = async (option: { value: string, label: string }) => {
  // Do nothing if the selected organization is the same as the current one
  if (currentUser.value?.organization_id === option.value) {
    return
  }

  // Route to main page and send the new org_id in.
  // The routeOrganizationManager will pick up the new id and sign the user in
  await router.push({ path: '/', query: { org_id: selectedOrg.value?.value } })
}
</script>

<style scoped>
.select,
.org-switcher-overlay {
  --p-select-background: var(--p-surface-950);
  --p-select-border-color: var(--p-surface-600);
  --p-select-hover-border-color: var(--p-surface-500);
  --p-select-color: var(--p-surface-0);
  --p-select-overlay-border-color: var(--p-surface-700);
  --p-select-option-color: var(--p-surface-0);
  --p-select-option-selected-color: var(--p-surface-0);
  --p-select-option-focus-background: var(--p-surface-800);
  --p-select-option-focus-color: var(--p-surface-0);
  --p-select-option-selected-focus-color: var(--p-surface-0);
  --p-select-option-selected-background: color-mix(in srgb, var(--p-primary-400), transparent 84%);
  --p-select-option-selected-focus-background: color-mix(in srgb, var(--p-primary-400), transparent 76%);
  --p-select-overlay-background: var(--p-surface-900);
}
</style>
